import { BeneficiaryType, ContractStatus, FocusArea, PaymentStatus, Portfolio } from '@grunfin/ui-kit';
import type { CompanyRelatedPerson, Customer } from '../account/types';

export interface PublicPortfolio extends Pick<Portfolio, 'id' | 'name' | 'referenceNumber' | 'beneficiaryType'> {
  firstName: string;
}

type PortfolioDraftFields =
  | 'monthlyInvestmentAmountEur'
  | 'periodInYears'
  | 'upfrontInvestmentAmountEur'
  | 'preferredRisk';

export type DraftPortfolio = Partial<Pick<Portfolio, PortfolioDraftFields>>;
export type UpdatePortfolio = {
  focusAreas: FocusArea[];
  areaSP500: boolean;
  areaClimateChange: boolean;
  areaGenderEquality: boolean;
  areaHealth: boolean;
  monthlyInvestmentAmountEur: string;
  name: string;
  preferredRisk: string;
  endDate: string;
};

export interface PortfolioTransaction {
  currencyCode: string;
  amount: number;
  description: string;
  date: string;
  type: PortfolioTransactionType;
  status: PortfolioFeeStatus;
}

export enum PortfolioTransactionType {
  FEE = 'FEE',
  PAYMENT = 'PAYMENT',
}

export enum PortfolioFeeStatus {
  PAID = 'PAID',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
}

export enum PricingScheme {
  RETAIL_AUM = 'RETAIL_AUM',
  RETAIL_LEGACY_FLAT = 'RETAIL_LEGACY_FLAT',
  COMPANY_AUM = 'COMPANY_AUM',
  EMPLOYER_FREE = 'EMPLOYER_FREE',
  EMPLOYER_AUM = 'EMPLOYER_AUM',
}

export interface PortfolioStatement {
  id: string;
  name: string;
  originalFileName: string;
  fileId: string;
}

export interface PortfolioContract {
  id: string;
  customer: Customer;
  manualSigningAttempt: SigningAttempt;
  signedAt: string | null;
  contractDate: string;
  pdfUrl: string;
}

export interface StandingOrderInstruction {
  beneficiaryName: string;
  accountNumber: string;
  referenceNumber: string;
  totalMonthlyAmount: number;
  monthlyFee: number;
  monthlyInvestmentAmount: number;
  upfrontInvestmentAmount: number;
  portfolioType: BeneficiaryType;
}

export interface DirectDebitConsent {
  id: string;
  provider: string;
  iban: string;
  nameOnAccount: string;
}

export interface PortfolioDirectDebitResponse {
  availableConsents: DirectDebitConsent[];
  currentlySubscribedConsentId: string | undefined;
  directDebitMethodVisible: boolean;
}

export interface StripeSessionRequest {
  successUrl?: string;
  cancelUrl?: string;
}

export interface StripeSessionResponse {
  url: string;
}

export interface TopUpRequest {
  consentId?: string;
  amount: number;
  successUrl?: string;
  cancelUrl?: string;
}

export interface OneTimePayment {
  email: string;
  amount: number;
  successUrl?: string;
  cancelUrl?: string;
}

export interface ContributionSettings {
  message?: string;
  name?: string;
  beneficiaryName?: string;
  accountNumber?: string;
  referenceNumber?: string;
  paymentCode?: string;
}

export interface Contribution {
  message: string;
  amount: number;
  email: string;
  referenceNumber?: string;
  paymentCode?: string;
  newsletters?: boolean;
}

export interface TopUpResponse {
  url?: string;
}

export interface SubscribeConsentToPortfolioPayload {
  portfolioId: string;
  consentId: string;
}

export enum SigningAttemptStatus {
  NOT_INITIATED = 'NOT_INITIATED',
  INITIATED = 'INITIATED',
  SIGNED = 'SIGNED',
  TIMED_OUT = 'TIMED_OUT',
  FAILED = 'FAILED',
  SENDING_ERROR = 'SENDING_ERROR',
}

export interface SigningAttempt {
  phoneCountryCode: string;
  phoneNumber: string;
  status: SigningAttemptStatus;
  wrongAnswerAttemptCount: number;
  verificationCode: string;
  redirectUrl: string;
}

export enum PortfolioStatus {
  NEW = 'NEW',
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  CLOSED = 'CLOSED',
  ARCHIVED = 'ARCHIVED',
  EXITED = 'EXITED',
}

export const DRAFT_PORTFOLIO_STATUSES = [PortfolioStatus.NEW, PortfolioStatus.PENDING_ACTIVATION];

export enum VerificationState {
  WAITING_FOR_UPLOAD = 'WAITING_FOR_UPLOAD',
  UPLOADED_AND_PENDING = 'UPLOADED_AND_PENDING',
  PROBLEMS = 'PROBLEMS',
  APPROVED = 'APPROVED',
}

export interface IdentityVerificationStatus {
  veriffSessionUrl: string;
  verificationState: VerificationState;
}

export interface PortfolioChild {
  id: string;
  givenName: string;
  lastName: string;
  dateOfBirth: string;
}

export interface Company {
  id: string;
  name: string;
  registrationNumber: string;
  lei: string;
  companyId: string;
  legalAddress: string;
  sourceOfFunds: string;
  businessActivityDescription: string;
  zipCode: string;
  taxResidencyCountryCode: string | null;
  taxId: string | null;
  isUSTaxResident: boolean | null;
  relatedPersons?: CompanyRelatedPerson[];
  manualFill: boolean | null;
}

export interface PortfolioStatusResponse {
  contract: ContractStatus;
  payment: PaymentStatus;
  childDetails: 'OK' | 'PENDING';
  company: CompanyStatus;
}

export enum CompanyStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  NEEDS_DOCUMENTS = 'NEEDS_DOCUMENTS',
  VALID = 'VALID',
  NOT_VALID = 'NOT_VALID',
}

export interface PortfolioImpactResponse {
  focuses: string[];
  descriptions: PortfolioImpactDescriptionResponse[];
}

export interface PortfolioImpactDescriptionResponse {
  value: string;
  lang: string;
  image: string;
  orderBy: number;
}

export type PortfolioPerformancePaymentsResponse = {
  date: string;
  value: number | undefined;
};

export type PortfolioPerformanceResponse = {
  currency: string;
  date: string;
  totalValue: number;
};

export type PortfolioPerformancePredictionResponse = {
  month: string | undefined;
  predictedTotalPayments: number | undefined;
  predictedPortfolioValue: number | undefined;
};
