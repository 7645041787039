import { BeneficiaryType, Button, ButtonVariant, Spinner, Tabs, useTranslation } from '@grunfin/ui-kit';
import { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import 'twin.macro';
import { useSession } from '~/modules/auth/SessionProvider';
import { useGetBanks } from '~/modules/gift/contribute/queries';
import { Bank } from '~/modules/gift/contribute/types';
import {
  useGetDirectDebitConsents,
  useGetPortfolio,
  useGetPortfolioStandingOrderInstruction,
} from '~/modules/portfolio/queries';
import { PortfolioStatus, StandingOrderInstruction } from '~/modules/portfolio/types';
import { trackPurchase } from '~/utils/tracking-analytics';
import { DirectDebitPaymentMethod } from './DirectDebitPaymentMethod';
import { InitialPaymentMethod } from './InitialPaymentMethod';
import { PaymentInformation } from './PaymentInformation';
import { StandingOrderPaymentMethod } from './StandingOrderPaymentMethod';
import { OnboardingOutletContext } from '~/modules/application/OnboardingWizard';

enum PaymentOption {
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  STANDING_ORDER = 'STANDING_ORDER',
  INITIAL_PAYMENT = 'INITIAL_PAYMENT',
  MANUAL = 'MANUAL',
}

export interface BankWithLink extends Bank {
  link: string;
}

interface Props {
  onNext: () => void;
  isUpfront?: boolean;
}

enum EstonianBankBicEnum {
  SEB = 'EEUHEE2X',
  SWEDBANK = 'HABAEE2X',
  LHV = 'LHVBEE22',
  COOP = 'EKRDEE22',
}

const bankBaseUrls = {
  [EstonianBankBicEnum.SWEDBANK]: 'https://www.swedbank.ee/private/d2d/payments2/standing_order/new',
  [EstonianBankBicEnum.SEB]: 'https://e.seb.ee/cgi-bin/ipank/ipank.p',
  [EstonianBankBicEnum.LHV]: 'https://www.lhv.ee/portfolio/payment_standing_add.cfm',
  [EstonianBankBicEnum.COOP]: 'https://i.cooppank.ee/permpmtnew',
};

export const PaymentStep = ({ isUpfront, onNext }: Props) => {
  const context = useOutletContext<OnboardingOutletContext>();
  const { onBack } = context ?? {};
  const { t, i18n } = useTranslation('portfolio');
  const params = useParams<'id'>();
  if (!params.id) throw new Error('Missing id');
  const session = useSession();
  const portfolio = useGetPortfolio(params.id);
  const directDebitConsents = useGetDirectDebitConsents(params.id);
  const orderInstructions = useGetPortfolioStandingOrderInstruction(params.id);

  const isEstonian = session.cfIpcountry === 'EE' || i18n.language === 'et';
  const isRecurringBankLinksEnabled = portfolio?.data?.beneficiaryType !== BeneficiaryType.COMPANY;
  const useBanks = useGetBanks({
    enabled: isEstonian && isRecurringBankLinksEnabled,
  });
  const estonianBanks = useBanks.data?.EE ?? [];

  const getOptions = () => {
    const options = [];
    const isDirectDebitVisibleForCustomer = directDebitConsents.data?.directDebitMethodVisible;
    const showStripeDirectDebitOption = isUpfront && isDirectDebitVisibleForCustomer;
    const showInitialPaymentOption = isUpfront && portfolio?.data?.upfrontInvestmentAmountEur;
    const showStandingOrderOption = !isUpfront && portfolio?.data?.monthlyInvestmentAmountEur;

    if (showStripeDirectDebitOption) options.push(PaymentOption.DIRECT_DEBIT);
    if (showInitialPaymentOption && !isDirectDebitVisibleForCustomer) options.push(PaymentOption.INITIAL_PAYMENT);
    if (showStandingOrderOption && isRecurringBankLinksEnabled) options.push(PaymentOption.STANDING_ORDER);
    options.push(PaymentOption.MANUAL);
    return Array.from(new Set(options));
  };

  const defaultTab = getOptions()[0];
  const [currentTab, setCurrentTab] = useState<PaymentOption>(defaultTab);

  // temp fix for when defaultTab updates on sequential renders
  useEffect(() => {
    if (defaultTab) setCurrentTab(defaultTab);
  }, [defaultTab]);

  const status = portfolio?.data?.status;
  useEffect(() => {
    if (status === PortfolioStatus.ACTIVE) trackPurchase(portfolio.data);
  }, [portfolio.data, status]);

  if (directDebitConsents.isIdle || directDebitConsents.isLoading) {
    return (
      <div tw="flex items-center justify-center text-alps-blue-700" css={{ minHeight: 220 }}>
        <Spinner width="64" height="64" />
      </div>
    );
  }

  const createBankLinkWithBic = (bic: EstonianBankBicEnum) => {
    const beneficiaryAccountNumber = orderInstructions.data?.accountNumber ?? '',
      beneficiaryName = orderInstructions.data?.beneficiaryName ?? '',
      referenceNumber = orderInstructions.data?.referenceNumber ?? '',
      amount =
        portfolio.data?.monthlyInvestmentAmountEur != null
          ? (portfolio.data?.monthlyInvestmentAmountEur + 3.9).toFixed(2)
          : '',
      frequency = 'K',
      url = new URL(bankBaseUrls[bic]);

    switch (bic) {
      case EstonianBankBicEnum.LHV:
        url.searchParams.set('i_receiver_name', beneficiaryName);
        url.searchParams.set('i_receiver_account_no', beneficiaryAccountNumber);
        url.searchParams.set('i_payment_clirefno', referenceNumber);
        url.searchParams.set('i_amount', amount);
        url.searchParams.set('i_interval_type', frequency);
        break;
      case EstonianBankBicEnum.COOP:
        url.searchParams.set('SaajaNimi', beneficiaryName);
        url.searchParams.set('SaajaKonto', beneficiaryAccountNumber);
        url.searchParams.set('ViiteNumber', referenceNumber);
        url.searchParams.set('MakseSumma', amount);
        url.searchParams.set('Vaaring', 'EUR');
        url.searchParams.set('MakseSagedus', frequency === 'K' ? '3' : ''); // COOP has different kind of naming for the frequency
        break;
      case EstonianBankBicEnum.SEB:
        url.searchParams.set('UID', '229c2840-a858-42a5-9aba-bdbc2696db34');
        url.searchParams.set('lang', 'EST');
        url.searchParams.set('field1', 'benname');
        url.searchParams.set('value1', beneficiaryName);
        url.searchParams.set('field3', 'benacc');
        url.searchParams.set('value3', beneficiaryAccountNumber);
        url.searchParams.set('field5', 'amount');
        url.searchParams.set('value5', amount);
        url.searchParams.set('field6', 'currency');
        url.searchParams.set('value6', 'EUR');
        url.searchParams.set('field11', 'refid');
        url.searchParams.set('value11', referenceNumber);
        url.searchParams.set('sofield1', 'frequency');
        url.searchParams.set('sovalue1', frequency === 'K' ? '3' : ''); // SEB has different kind of naming for the frequency
        url.searchParams.set('sofield2', 'startdt');
        break;
      case EstonianBankBicEnum.SWEDBANK:
        url.searchParams.set('standingOrder.beneficiaryAccountNumber', beneficiaryAccountNumber);
        url.searchParams.set('standingOrder.beneficiaryName', beneficiaryName);
        url.searchParams.set('standingOrder.beneficiaryReferenceNumber', referenceNumber);
        url.searchParams.set('standingOrder.amount', amount);
        url.searchParams.set('frequency', frequency);
        break;
      default:
        return null;
    }

    return url.href;
  };

  const banksWithLinks = estonianBanks
    .map((bank) => {
      // do not show banks that we don't have a EstonianBankBicEnum for
      if (!Object.values(EstonianBankBicEnum).includes(bank.bic as EstonianBankBicEnum)) return null;
      return {
        ...bank,
        link: createBankLinkWithBic(bank.bic as EstonianBankBicEnum),
      };
    })
    .filter(Boolean) as BankWithLink[];

  const tabComponents = {
    [PaymentOption.DIRECT_DEBIT]: (
      <DirectDebitPaymentMethod portfolioId={params.id} directDebitConsents={directDebitConsents} onNext={onNext} />
    ),
    [PaymentOption.STANDING_ORDER]: (
      <StandingOrderPaymentMethod banks={banksWithLinks} orderInstructions={orderInstructions} onNext={onNext} />
    ),
    [PaymentOption.INITIAL_PAYMENT]: <InitialPaymentMethod orderInstructions={orderInstructions} onNext={onNext} />,
    [PaymentOption.MANUAL]: (
      <div tw="flex flex-col gap-8">
        <div tw="rounded-lg my-4 bg-green-50 p-4 md:p-8 max-w-lg">
          <PaymentInformation {...(orderInstructions.data as StandingOrderInstruction)} isUpfront={isUpfront} />
        </div>
        <div tw="flex flex-row w-full">
          <Button variant={ButtonVariant.NEW_SECONDARY} onClick={() => onBack()} tw="max-w-max">
            {t('back', { ns: 'general' })}
          </Button>
          <Button
            tw="ml-auto max-w-max"
            variant={ButtonVariant.NEW_PRIMARY}
            onClick={onNext}
            className="max-w-max"
            data-test-id="payment-done"
          >
            {t('activation.payment.manual.proceed')}
          </Button>
        </div>
      </div>
    ),
  };

  return (
    <Tabs
      options={getOptions().map((key) => ({
        value: key,
        label: t(`activation.payment.method.${key.toLowerCase()}`),
        component: tabComponents[key as keyof typeof PaymentOption],
      }))}
      value={currentTab}
      onValueChange={(value) => setCurrentTab(PaymentOption[value as keyof typeof PaymentOption])}
    />
  );
};
