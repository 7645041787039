import { useMutation, useQuery } from 'react-query';
import { api } from '~/api';
import { BanksResponse, PaymentParams } from '~/modules/gift/contribute/types';
import { UseQueryOptions } from 'react-query/types/react/types';

export const useGetBanks = (options?: UseQueryOptions<BanksResponse, Error>) =>
  useQuery<BanksResponse, Error>(
    'banks',
    async () => api.get('payments/montonio/banks').json<BanksResponse>(),
    options,
  );

export const useMontonioPayment = () =>
  useMutation(async (payload: PaymentParams) =>
    api.post('payments/montonio', { json: payload, timeout: 180000 }).then(async (res) => {
      if (res.ok) {
        window.location.href = await res.text();
      } else {
        throw new Error(`Unable to redirect to Montonio: ${payload}`);
      }
    }),
  );
